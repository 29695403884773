import LogRocket from 'logrocket';

export function identify(uid: string, traits?: Record<string, string>) {
  if (process.env.NODE_ENV === 'production') {
    LogRocket.identify(uid, traits);
  }
}

export function init() {
  if (process.env.NODE_ENV === 'production') {
    LogRocket.init(process.env.NEXT_PUBLIC_LOG_ROCKET_APP_ID as string);
  }
}
