export const DEFAULT_EMPTY_MESSAGE = 'Data tidak tersedia.';

export const DEFAULT_ERROR_MESSAGE = 'Maaf, kami tidak bisa memproses permintaan Anda saat ini. Mohon mencoba beberapa saat lagi.';

export const FEATURES = {
  PROMOTION: 'promotion',
};

export const FIRESTORE_COLLECTIONS = {
  FEATURES: 'features',
  PRODUCTS: 'products',
  PROMOTIONS: 'promotions',
  REDEMPTIONS: 'redemptions',
  TRANSACTIONS: 'transactions',
  UPDATES: 'updates',
  USERS: 'users',
};
