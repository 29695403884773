import React from 'react';
// Modules
import * as Sentry from '@sentry/node';
import axios from 'axios';
// Firebase
import firebase from '../firebase/client';
// Utils
import { FIRESTORE_COLLECTIONS } from '../utils/constants';
import { Role } from '../utils/interfaces';
import * as logrocket from '../utils/logrocket';

interface IAuthContext {
  currentUser: firebase.User | null;
  isLoading: boolean;
  points: number;
  role: Role | null;
}

const AuthContext = React.createContext<IAuthContext>({
  currentUser: null,
  isLoading: true,
  points: 0,
  role: null,
});

async function onIdTokenChanged(user: firebase.User | null) {
  try {
    if (user) {
      const token = await user.getIdToken();

      await axios.post('/api/log-in', { token });
    } else {
      await axios.post('/api/log-out');
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

export const AuthProvider: React.FC = ({ children }) => {
  const [currentUser, setCurrentUser] = React.useState<firebase.User | null>(null);
  const [isLoading, setLoading] = React.useState(true);
  const [points, setPoints] = React.useState(0);
  const [role, setRole] = React.useState<Role | null>(null);

  React.useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);

      if (user) {
        firebase.analytics().setUserId(user.uid);

        logrocket.identify(user.uid, {
          email: user.email ?? '-',
          name: user.displayName ?? '-',
        });

        Sentry.setUser({
          email: user.email ?? undefined,
          id: user.uid,
        });
      } else {
        Sentry.setUser(null);
      }
    });

    return unsubscribe;
  }, []);

  React.useEffect(() => {
    const unsubscribe = firebase.auth().onIdTokenChanged((user) => {
      onIdTokenChanged(user);
    });

    return unsubscribe;
  }, []);

  React.useEffect(() => {
    let unsubscribe = () => {};

    if (currentUser) {
      unsubscribe = firebase
        .firestore()
        .collection(FIRESTORE_COLLECTIONS.USERS)
        .doc(currentUser.uid)
        .onSnapshot(
          (snapshot) => {
            const docData = snapshot.data();

            if (docData) {
              setPoints(docData.points);
            } else {
              setPoints(0);
            }
          },
          (error) => {
            setPoints(0);

            Sentry.captureException(error);
          },
        );
    } else {
      setPoints(0);
    }

    return unsubscribe;
  }, [currentUser]);

  React.useEffect(() => {
    if (currentUser) {
      currentUser
        .getIdTokenResult()
        .then((value) => {
          setRole(value.claims.role);
        })
        .catch(() => {
          setRole(null);
        });
    } else {
      setRole(null);
    }
  }, [currentUser]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isLoading,
        points,
        role,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
